import React from "react";
import classnames from "classnames";

import { Link as GatsbyLink } from "gatsby";
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

// STYLE
import "./link.scss";

const Link = ({
    children,
    to,
    activeClassName,
    partiallyActive,
    className,
    ...other
}) => {
    const internal = /^\/(?!\/)/.test(to);

    if (internal) {
        return (
            <GatsbyLink
                to={to}
                activeClassName={activeClassName ?? "link--active"}
                partiallyActive={partiallyActive}
                className={classnames("link", className)}
                {...other}
                onMouseDown={(e) => e.preventDefault()}
            >
                {children}
            </GatsbyLink>
        );
    }
    return (
        <Link
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            {...other}
            onMouseDown={(e) => e.preventDefault()}
        >
            {children}
        </Link>
    );
};

export default Link;
