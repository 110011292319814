import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
    logIn,
    logOut,
    updateFavorites,
    updateStartedRoutes,
    updateFinishedRoutes,
    updatePlaces,
    updateStats,
} from "../../state/actions";

export const useAuth = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("auth"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            logIn,
            logOut,
            updateFavorites,
            updateStartedRoutes,
            updateFinishedRoutes,
            updatePlaces,
            updateStats,
        }
    );

    return [state, actions];
};

export default useAuth;
