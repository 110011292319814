import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";

// ACTIONS
import { logOut } from "../../../state/actions";

// COMPONENTS
import Heading from "../../atoms/Heading/Heading";
import Icon from "../../atoms/Icon/Icon";
import PlainText from "../../atoms/PlainText/PlainText";
import Link from "../../atoms/Link/Link";

// ASSETS
import logo from "../../../assets/images/logo.svg";
import UserPlaceholder from "../../../assets/images/user-placeholder.png";
import {
    useAboutModal,
    useAuth,
    useSideDrawer,
    useDeclarationModal,
    useHeader,
} from "../../../hooks/redux";
// STYLE
import "./sideDrawer.scss";

const SideDrawer = () => {
    const dispatch = useDispatch();
    const [auth] = useAuth();
    const [aboutModal, { showAboutModal }] = useAboutModal();
    const [declarationModal, { showDeclarationModal }] = useDeclarationModal();

    const sideDrawerRef = useRef();

    const [sideDrawer] = useSideDrawer();
    const [header] = useHeader();

    return header.visible ? (
        <nav
            ref={sideDrawerRef}
            className={classnames("side-drawer", {
                "side-drawer--open": sideDrawer.open,
            })}
        >
            <div className="side-drawer--content">
                {/* LOGO */}
                <div className="side-drawer--logo">
                    <Link to="/" tabIndex={!sideDrawer.open ? -1 : 1}>
                        <img className="img-fluid" src={logo} alt="logo" />
                    </Link>
                </div>
                {/* LOGO */}

                {/* AUTH LINKS */}
                {!auth.isLoggedIn && (
                    <>
                        <div className="side-drawer--register-btn">
                            <Link
                                className="btn--primary btn btn-default text-white"
                                to="/app/rejestracja"
                                tabIndex={!sideDrawer.open ? -1 : 2}
                            >
                                Załóż konto
                            </Link>
                        </div>
                        <Link
                            className="side-drawer--link__login"
                            to="/app/logowanie"
                            tabIndex={!sideDrawer.open ? -1 : 3}
                        >
                            Zaloguj się&nbsp;
                            <Icon className="icon-arrow-back" />
                        </Link>
                    </>
                )}
                {/* AUTH LINKS */}

                {/* USER INFO */}
                {auth.isLoggedIn && (
                    <div className="side-drawer--user">
                        <Link
                            to="/app/moje-konto"
                            tabIndex={!sideDrawer.open ? -1 : 4}
                        >
                            <div
                                className="side-drawer--user__avatar"
                                style={{
                                    backgroundImage: `url(${
                                        auth.data.avatar || UserPlaceholder
                                    })`,
                                }}
                            ></div>
                            <Heading
                                className="h2 side-drawer--user__name"
                                as="h2"
                            >
                                {auth.data.name}
                            </Heading>
                            <PlainText className="side-drawer--user__phone">
                                {auth.data.phone}
                            </PlainText>
                        </Link>
                    </div>
                )}
                {/* USER INFO */}

                {/* LINKS */}
                <ul className="side-drawer--links">
                    <li className="side-drawer--link">
                        <a
                            href="/"
                            className="link"
                            onClick={(e) => {
                                e.preventDefault();
                                e.target.blur();
                                showAboutModal();
                            }}
                            tabIndex={!sideDrawer.open ? -1 : 5}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            O aplikacji
                        </a>
                    </li>
                    <li className="side-drawer--link">
                        <a
                            href="/"
                            className="link"
                            onClick={(e) => {
                                e.preventDefault();
                                e.target.blur();
                                showDeclarationModal();
                            }}
                            tabIndex={!sideDrawer.open ? -1 : 6}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            Deklaracja dostępności
                        </a>
                    </li>
                    <li className="side-drawer--link">
                        <Link to="/pomoc" tabIndex={!sideDrawer.open ? -1 : 7}>
                            Pomoc
                        </Link>
                    </li>
                    {auth.isLoggedIn && (
                        <li className="side-drawer--link side-drawer--link__logout">
                            <Link
                                to="/app/logowanie"
                                onClick={() => dispatch(logOut())}
                                tabIndex={!sideDrawer.open ? -1 : 8}
                            >
                                <Icon className="icon-arrow-back" />
                                &nbsp;Wyloguj
                            </Link>
                        </li>
                    )}
                </ul>
                {/* LINKS */}
            </div>
        </nav>
    ) : null;
};

export default SideDrawer;
