import React, { useEffect } from "react";

import "./declarationModal.scss";
import { useSideDrawer, useDeclarationModal } from "../../../hooks/redux";
import Modal from "../Modal/Modal";
import Heading from "../../atoms/Heading/Heading";
import * as Strapi from "../../../hooks/strapi";

const DeclarationModal = () => {
    const declaration = Strapi.useDeclaration();
    const [sideDrawer, { hideSideDrawer }] = useSideDrawer();
    const [declarationModal, { hideDeclarationModal }] = useDeclarationModal();

    useEffect(() => {
        if (declarationModal.visible) {
            hideSideDrawer();
        }
    }, [declarationModal.visible]);

    return (
        <Modal
            modal={declarationModal.visible}
            setModal={() => hideDeclarationModal()}
        >
            <Heading as="h2" style={{ fontSize: "2rem" }}>
                {declaration.name}
            </Heading>

            <div
                dangerouslySetInnerHTML={{ __html: declaration.description }}
                className="declaration-modal"
            ></div>
        </Modal>
    );
};

export default DeclarationModal;
