import { graphql, useStaticQuery } from "gatsby";

export const useFAQs = () => {
    const {
        allStrapiFaqs: { nodes },
    } = useStaticQuery(graphql`
        query {
            allStrapiFaqs {
                nodes {
                    name
                    description
                }
            }
        }
    `);

    return nodes;
};

export default useFAQs;
