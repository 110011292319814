import React from "react";

// STYLE
import "./modal.scss";

import {
    Modal as ReactModal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";

const Modal = ({
    children,
    headerIcon,
    modal,
    setModal,
    className,
    BottomActions,
}) => (
    <ReactModal isOpen={modal} toggle={setModal} contentClassName={className}>
        <ModalHeader className="modal--header">
            {headerIcon && (
                <div className="icon--container">
                    <Icon className={headerIcon} />
                </div>
            )}
            <Button
                className="btn--circle modal--close-modal"
                onClick={setModal}
                aria-label="Zamknij"
            >
                <Icon className="icon-close-min" />
            </Button>
        </ModalHeader>
        <ModalBody className="modal--body">{children}</ModalBody>
        {BottomActions && (
            <ModalFooter className="modal--footer">
                <BottomActions />
            </ModalFooter>
        )}
    </ReactModal>
);

export default Modal;
