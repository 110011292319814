import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { hideRanksModal, showRanksModal } from "../../state/actions";

export const useRanksModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("ranksModal"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            hideRanksModal,
            showRanksModal,
        }
    );

    return [state, actions];
};

export default useRanksModal;
