import { graphql, useStaticQuery } from "gatsby";

export const useAbout = () => {
    const { strapiAbout } = useStaticQuery(graphql`
        query {
            strapiAbout {
                name
                description
            }
        }
    `);

    return strapiAbout;
};

export default useAbout;
