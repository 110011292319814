/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useLocationDeniedModal, useRoutes } from "../hooks/redux";
import PropTypes from "prop-types";
import SideDrawer from "../components/molecules/SideDrawer/SideDrawer";
import BottomNav from "../components/molecules/BottomNav/BottomNav";
import { AppContainer } from "../components/molecules/AppContainer/AppContainer";
import RegisterBenefitsModal from "../components/molecules/RegisterBenefitsModal/RegisterBenefitsModal";
import RanksInfoModal from "../components/molecules/RanksInfoModal/RanksInfoModal";
import AboutModal from "../components/molecules/AboutModal/AboutModal";
import DeclarationModal from "../components/molecules/DeclarationModal/DeclarationModal";
import InstallMessage from "../components/molecules/InstallMessage/InstallMessage";
import LocationDeniedModal from "../components/molecules/LocationDeniedModal/LocationDeniedModal";
import axios from "axios";
import updateCssVariables from "../utils/updateCssVariables";

// TOGGLE CAPTCHA BADGE
import toggleCaptchaBadge from "../utils/toggleCaptchaBadge";
import { update } from "ramda";

const Layout = ({ children }) => {
    const [routes, { setRoutes }] = useRoutes();
    const [
        locationDeniedModal,
        { showLocationDeniedModal, hideLocationDeniedModal },
    ] = useLocationDeniedModal();

    const location = useLocation();

    useEffect(() => {
        if (routes.routes.length === 0) {
            axios
                .get(`${process.env.STRAPI_API_URL}/routes`)
                .then((response) => {
                    let newRoutes = [];
                    if (response.data && response.data.length) {
                        newRoutes = response.data.filter(
                            (route) =>
                                route.difficulty &&
                                route.places &&
                                route.places.length
                        );
                    }
                    console.log(newRoutes);
                    setRoutes(newRoutes);
                })
                .catch((error) => {
                    console.log(error);
                });

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    () => {
                        localStorage.setItem("locationStatus", "allowed");
                        hideLocationDeniedModal();
                    },
                    () => {
                        localStorage.setItem("locationStatus", "denied");
                        showLocationDeniedModal();
                    }
                );
            }
        }

        toggleCaptchaBadge();
        updateCssVariables();

        window.addEventListener("resize", updateCssVariables);
    }, []);

    useEffect(() => {
        handleCaptchaBadge();
    }, [location]);

    const handleCaptchaBadge = () => {
        const allowBadge = [
            "logowanie",
            "rejestracja",
            "przypomnienie-hasla",
            "pomoc",
        ];
        const isAllow =
            allowBadge.filter((path) => location.pathname.includes(path))
                .length !== 0;

        setTimeout(() => toggleCaptchaBadge(isAllow), 600);
    };

    return (
        <>
            <SideDrawer />
            <BottomNav />
            <AppContainer>{children}</AppContainer>
            <RegisterBenefitsModal />
            <RanksInfoModal />
            <AboutModal />
            <DeclarationModal />

            <InstallMessage />
            <LocationDeniedModal />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
