import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { hideAboutModal, showAboutModal } from "../../state/actions";

export const useAboutModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("aboutModal"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            hideAboutModal,
            showAboutModal,
        }
    );

    return [state, actions];
};

export default useAboutModal;
