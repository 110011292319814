import { graphql, useStaticQuery } from "gatsby";

export const useTerms = () => {
    const { strapiTermsAndConditions } = useStaticQuery(graphql`
        query {
            strapiTermsAndConditions {
                name
                description
            }
        }
    `);

    return strapiTermsAndConditions;
};

export default useTerms;
