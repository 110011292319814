import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { normalFont, biggerFont, biggestFont } from "../../state/actions";

export const useAccessibility = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("accessibility"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            normalFont,
            biggerFont,
            biggestFont,
        }
    );

    return [state, actions];
};

export default useAccessibility;
