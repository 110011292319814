import React from "react";

import "./locationDeniedModal.scss";
import { useLocationDeniedModal } from "../../../hooks/redux";
import Modal from "../Modal/Modal";
import Heading from "../../atoms/Heading/Heading";
import Button from "../../atoms/Button/Button";
import isBrowser from "../../../utils/isBrowser";

const LocationDeniedModal = () => {
    const [locationDeniedModal, { hideLocationDeniedModal }] =
        useLocationDeniedModal();
    const isInStandaloneMode = () =>
        isBrowser()
            ? "standalone" in window.navigator && window.navigator.standalone
            : "false";

    return (
        <Modal
            className="modal--xs"
            modal={locationDeniedModal.visible}
            setModal={() => hideLocationDeniedModal()}
            BottomActions={() => (
                <>
                    <Button
                        className="btn--primary btn--md btn-block mb-3"
                        onClick={() => {
                            hideLocationDeniedModal();
                        }}
                    >
                        Zamknij
                    </Button>
                </>
            )}
        >
            <Heading as="h2">Nie wyrażono zgody na lokalizację</Heading>

            {isInStandaloneMode ? (
                <div>
                    Aby w pełni korzystać z funkcji aplikacji, należy w
                    ustawieniach przeglądarki zezwolić na lokalizcję i odświeżyć
                    stronę.
                </div>
            ) : (
                <div>
                    Aby w pełni korzystać z funkcji aplikacji, należy ponownie
                    uruchomić aplikację i zezwolić na lokalizację.
                </div>
            )}
        </Modal>
    );
};

export default LocationDeniedModal;
