import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { hideHeader, showHeader, toggleHeader } from "../../state/actions";

export const useHeader = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("header"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            hideHeader,
            showHeader,
            toggleHeader,
        }
    );

    return [state, actions];
};

export default useHeader;
