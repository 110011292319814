import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
    hideLocationDeniedModal,
    showLocationDeniedModal,
} from "../../state/actions";

export const useLocationDeniedModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("locationDeniedModal"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            hideLocationDeniedModal,
            showLocationDeniedModal,
        }
    );

    return [state, actions];
};

export default useLocationDeniedModal;
