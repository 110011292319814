import React, { useState, useEffect } from "react";
import isBrowser from "../../../utils/isBrowser";

import Button from "../../atoms/Button/Button";
import Paragraph from "../../atoms/Paragraph/Paragraph";

import shareIcon from "../../../assets/images/share-icon.svg";

import "./installMessage.scss";

export const InstallMessage = () => {
    const [showInstallMessage, setShowInstallMessage] = useState(false);

    const isIos = () => {
        const userAgent = isBrowser()
            ? window.navigator.userAgent.toLowerCase()
            : "";
        return /iphone|ipad|ipod/.test(userAgent) && !/CriOS/i.test(userAgent);
    };

    const isInStandaloneMode = () =>
        isBrowser()
            ? "standalone" in window.navigator && window.navigator.standalone
            : "false";

    useEffect(() => {
        const isCanceled = isBrowser()
            ? sessionStorage.getItem("noInstallMsg")
            : false;

        if (isIos() && !isInStandaloneMode() && !isCanceled) {
            setShowInstallMessage(true);
        }
    }, []);

    return showInstallMessage ? (
        <div className="install-message">
            <Paragraph>
                Zainstaluj aplikację na swoim telefonie, aby mieć do niej łatwy
                dostęp. <br />
                Wybierz <img src={shareIcon} alt="ikona udostępaniania" />, a
                następnie "Dodaj do ekranu początkowego".
            </Paragraph>
            <Button
                className="btn--secondary"
                onClick={() => {
                    setShowInstallMessage(false);

                    if (isBrowser()) {
                        sessionStorage.setItem("noInstallMsg", true);
                    }
                }}
            >
                Nie pokazuj więcej
            </Button>
        </div>
    ) : (
        ""
    );
};

export default InstallMessage;
