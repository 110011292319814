import React from "react";
import Header from "../../layout/header";
import classnames from "classnames";

import "./appContainer.scss";
import { useSideDrawer } from "../../../hooks/redux";

export const AppContainer = ({ children }) => {
    const [sideDrawer, _actions] = useSideDrawer();

    return (
        <div
            className={classnames("app--container", {
                "app--container__shrink": sideDrawer.open,
            })}
            onClick={() => sideDrawer.open && _actions.hideSideDrawer()}
        >
            {sideDrawer.open && (
                <div
                    className="app--container__overlay"
                    onClick={() => _actions.hideSideDrawer()}
                ></div>
            )}
            <Header />
            <main>{children}</main>
        </div>
    );
};
