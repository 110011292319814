import React, { useState, useEffect } from "react";

import * as Strapi from "../../../hooks/strapi";
import { useRegisterModal } from "../../../hooks/redux";

// STYLE
import "./registerBenefitsModal.scss";

// COMPONENTS
import Button from "../../atoms/Button/Button";
import Modal from "../Modal/Modal";
import { navigate } from "gatsby";
import isBrowser from "../../../utils/isBrowser";

const RegisterBenefitsModal = () => {
    const global = Strapi.useGlobal();
    const [registerModal, { hideRegisterModal }] = useRegisterModal();
    const [redirectUrl, setRedirectUrl] = useState("");

    useEffect(() => {
        if (isBrowser()) {
            setRedirectUrl(window.location.pathname);
        }
    }, [registerModal]);

    return (
        <Modal
            className="modal--sm modal--register-benefits"
            modal={registerModal.visible}
            setModal={() => hideRegisterModal()}
            BottomActions={() => (
                <>
                    <Button
                        className="btn--primary btn--md btn-block mb-3"
                        onClick={async () => {
                            await hideRegisterModal();
                            if (redirectUrl) {
                                navigate("/app/rejestracja", {
                                    state: { redirectUrl: redirectUrl },
                                });
                            } else {
                                navigate("/app/rejestracja");
                            }
                        }}
                    >
                        Zarejestruj/zaloguj się
                    </Button>

                    {registerModal.continueUrl ? (
                        <Button
                            className="btn--primary btn--primary__outline btn--md btn-block px-3"
                            onClick={async () => {
                                await hideRegisterModal();
                                navigate(registerModal.continueUrl);
                            }}
                        >
                            Kontynuuj bez zakładania konta
                        </Button>
                    ) : (
                        ""
                    )}
                </>
            )}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: global.registration_benefits,
                }}
            ></div>
        </Modal>
    );
};

export default RegisterBenefitsModal;
