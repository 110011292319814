import React from "react";
import classnames from "classnames";

// STYLE
import "./icon.scss";

const Icon = ({ className, style, onClick, asButton, ariaLabel }) =>
    asButton ? (
        <button
            className={classnames("icon btn btn-default", className)}
            style={style}
            onClick={onClick}
        ></button>
    ) : (
        <span
            className={classnames("icon", className)}
            style={style}
            onClick={onClick}
        ></span>
    );

export default Icon;
