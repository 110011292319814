import { graphql, useStaticQuery } from "gatsby";

export const useDeclaration = () => {
    const { strapiAvailabilityDeclaration } = useStaticQuery(graphql`
        query {
            strapiAvailabilityDeclaration {
                name
                description
            }
        }
    `);

    return strapiAvailabilityDeclaration;
};

export default useDeclaration;
