import { graphql, useStaticQuery } from "gatsby";

export const useGlobal = () => {
    const { strapiGlobal } = useStaticQuery(graphql`
        query {
            strapiGlobal {
                contact_description
                contact_form_recipient
                contact_heading
                contact_subjects
                curiosities_description
                curiosities_heading
                home_description
                home_heading
                registration_benefits
                rank {
                    description
                    name
                    points_from
                    points_to
                    id
                }
                logo {
                    alternativeText
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                home_img {
                    alternativeText
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);

    return strapiGlobal;
};

export default useGlobal;
