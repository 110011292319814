import { graphql, useStaticQuery } from "gatsby";

export const usePrivacyPolicy = () => {
    const { strapiPrivacyPolicy } = useStaticQuery(graphql`
        query {
            strapiPrivacyPolicy {
                name
                description
            }
        }
    `);

    return strapiPrivacyPolicy;
};

export default usePrivacyPolicy;
