import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
    hideSideDrawer,
    showSideDrawer,
    toggleSideDrawer,
} from "../../state/actions";

export const useSideDrawer = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("sideDrawer"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            hideSideDrawer,
            showSideDrawer,
            toggleSideDrawer,
        }
    );

    return [state, actions];
};

export default useSideDrawer;
