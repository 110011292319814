import React from "react";
import { Container, Row, Col } from "reactstrap";
import classnames from "classnames";

import Button from "../atoms/Button/Button";
import Icon from "../atoms/Icon/Icon";
import Link from "../atoms/Link/Link";
import Accessibility from "../molecules/Accessibility/Accessibility";
import { useHeader, useSideDrawer } from "../../hooks/redux";

const Header = () => {
    const [sideDrawer, { toggleSideDrawer }] = useSideDrawer();
    const [header] = useHeader();

    return (
        <header
            className={classnames("header", {
                header__hidden: !header.visible,
            })}
        >
            <Container>
                <Row>
                    <Col>
                        <nav className="header--nav">
                            {/* HAMBURGER ICON */}
                            <Button
                                className="header--hamburger"
                                onClick={toggleSideDrawer}
                                aria-label={
                                    !sideDrawer.open
                                        ? "Mobilne Menu"
                                        : "Zamknij Menu"
                                }
                            >
                                <div></div>
                                <div></div>
                                <div></div>
                                <span>
                                    {!sideDrawer.open ? "Menu" : "Zamknij"}
                                </span>
                            </Button>
                            {/* HAMBURGER ICON */}

                            {/* ACCESSIBILITY */}
                            <Accessibility />
                            {/* ACCESSIBILITY */}

                            {/* <Link
                                className="btn--circle notifications-icon btn btn-default"
                                to="/powiadomienia"
                                aria-label="Powiadomienia"
                            >
                                <Icon className="icon-bell-min icon--alt" />
                            </Link> */}
                        </nav>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default Header;
