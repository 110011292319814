import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { setRoutes, setRoute } from "../../state/actions";

export const useRoutes = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("routes"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            setRoutes,
            setRoute,
        }
    );

    return [state, actions];
};

export default useRoutes;
