import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
    hideDeclarationModal,
    showDeclarationModal,
} from "../../state/actions";

export const useDeclarationModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("declarationModal"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            hideDeclarationModal,
            showDeclarationModal,
        }
    );

    return [state, actions];
};

export default useDeclarationModal;
