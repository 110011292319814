import React from "react";
import { useRanksModal } from "../../../hooks/redux";

// STYLE
import "./ranksInfoModal.scss";

// COMPONENTS
import Modal from "../Modal/Modal";
import Heading from "../../atoms/Heading/Heading";
import * as Strapi from "../../../hooks/strapi";

const Rank = ({ rank }) => {
    return (
        <div className="rank">
            <div className="rank__range">
                {rank.points_from !== rank.points_to ? (
                    <>
                        {rank.points_from}-{rank.points_to} pkt
                    </>
                ) : (
                    <>{rank.points_to} pkt</>
                )}
            </div>
            <div className="rank__content">
                <Heading as="h2" className="rank__title">
                    {rank.name}
                </Heading>
                <div className="rank__description">{rank.description}</div>
            </div>
        </div>
    );
};

const RanksInfoModal = ({ modal, setModal }) => {
    const global = Strapi.useGlobal();
    const [ranksModal, { hideRanksModal }] = useRanksModal();

    return (
        <Modal modal={ranksModal.visible} setModal={() => hideRanksModal()}>
            <Heading as="h2">Statusy i punkty odkrywcy</Heading>
            <ul className="list-unstyled">
                {global.rank.map((rank) => (
                    <Rank key={rank.id} rank={rank} />
                ))}
            </ul>
        </Modal>
    );
};

export default RanksInfoModal;
