import React, { useEffect } from "react";

import "./aboutModal.scss";
import { useSideDrawer, useAboutModal } from "../../../hooks/redux";
import Modal from "../Modal/Modal";
import Heading from "../../atoms/Heading/Heading";
import * as Strapi from "../../../hooks/strapi";
import logo from "../../../assets/images/logo.svg";

const AboutModal = () => {
    const about = Strapi.useAbout();
    const global = Strapi.useGlobal();
    const [sideDrawer, { hideSideDrawer }] = useSideDrawer();
    const [aboutModal, { hideAboutModal }] = useAboutModal();

    useEffect(() => {
        if (aboutModal.visible) {
            hideSideDrawer();
        }
    }, [aboutModal.visible]);

    return (
        <Modal modal={aboutModal.visible} setModal={() => hideAboutModal()}>
            {about.name && (
                <Heading className="about-modal--heading" as="h2">
                    {about.name}
                </Heading>
            )}
            <div className="about-modal--logotype">
                <img
                    className="img-fluid"
                    src={logo}
                    alt="Chojnice - ekran startowy"
                />
            </div>
            <div
                dangerouslySetInnerHTML={{ __html: about.description }}
                className="about-modal-content"
            ></div>
        </Modal>
    );
};

export default AboutModal;
