import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import {
    hideRegisterModal,
    showRegisterModal,
    setUrlRegisterModal,
} from "../../state/actions";

export const useRegisterModal = () => {
    const dispatch = useDispatch();
    const state = useSelector(R.prop("registerModal"));
    const actions = R.map(
        (fn) =>
            (...args) =>
                dispatch(fn(...args)),
        {
            hideRegisterModal,
            showRegisterModal,
            setUrlRegisterModal,
        }
    );

    return [state, actions];
};

export default useRegisterModal;
