import React from "react";

// COMPONENTS
import { Button as StrapButton } from "reactstrap";

// STYLE
import "./buttons.scss";

const Button = (props) =>
    props.withoutaction ? (
        <div {...props}></div>
    ) : (
        <StrapButton
            color="default"
            {...props}
            onClick={(e) => {
                e.target.blur();
                props.onClick && props.onClick(e);
            }}
            onMouseDown={(e) => e.preventDefault()}
        >
            {props.children}
        </StrapButton>
    );

export default Button;
